import { OverlayProvider } from "@toss/use-overlay";
import { ThemeProvider } from "@emotion/react";
import type { AppProps } from "next/app";
import { RecoilRoot } from "recoil";
import "@/styles/portal.css";
import "@/styles/reset.css";
import "@/styles/theme/font.css";

import naverPageView from "@/utils/naverPageView";
import { ChakraProvider } from "@chakra-ui/react";
import { getUserId, HackleProvider } from "@hackler/react-sdk";

import AOS from "aos";
import "aos/dist/aos.css";
import {
  chakraTheme,
  cooKeys,
  getUserProperties,
  // customEvent,
  GlobalStyle,
  initUserProperties,
  setCookie,
  theme,
} from "goi_common";
import Head from "next/head";
import { Router, useRouter } from "next/router";
import Script from "next/script";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import { Hydrate, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "slick-carousel/slick/slick.css";
import Layout from "@/components/Layout";
import { NextPage } from "next";
import { DialogRoot } from "@/components/Dialog/DialogRoot";
import PreviewServiceDialogRoot from "@/components/Packages/PreviewServiceDialogRoot";

import { HackleClient } from "@/utils/hackle";
import { GlobalModalRoot } from "@/components/Layout/GlobalModalRoot";

declare global {
  interface Window {
    kakao: any;
    naver: any;
    hackleClinet: any;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  const [previous, setPrevious] = useState<string[]>([]);

  useEffect(() => {
    if (!router.asPath.startsWith("/login")) {
      const newPrevious = [router.asPath, ...previous].slice(0, 5);
      setPrevious(newPrevious);
      setCookie(cooKeys.previous, JSON.stringify(newPrevious));
    }
  }, [router.asPath]);

  const googleTagManagerScript = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM}');
    `;

  const beusableScript = `
        (function(w, d, a){
            w.__beusablerumclient__ = {
                load : function(src){
                    var b = d.createElement("script");
                    b.src = src; b.async=true; b.type = "text/javascript";
                    d.getElementsByTagName("head")[0].appendChild(b);
                }
            };w.__beusablerumclient__.load(a + "?url=" + encodeURIComponent(d.URL));
        })(window, document, "//rum.beusable.net/load/b220104e212424u502");
    `;

  const handleResize = () => {
    const vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  const handleRouteChange = (url: string) => {
    if (process.env.NEXT_PUBLIC_VERCEL_ENV === "production") {
      naverPageView();
    }
  };

  useEffect(() => {
    AOS.init();
    const goiMemberId = localStorage.getItem("goi-member-id");

    if (goiMemberId) setMemberId(goiMemberId);
  }, []);

  useEffect(() => {
    Router.events.on("routeChangeComplete", handleRouteChange);

    return () => Router.events.off("routeChangeComplete", handleRouteChange);
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [memberId, setMemberId] = useState("");

  useEffect(() => {
    if (HackleClient) {
      initUserProperties();
      let id = getUserId();

      if (memberId) {
        id = memberId;
      }

      const user = {
        userId: id,
        properties: getUserProperties(),
      };

      HackleClient.setUser(user);
    }
  }, [memberId]);

  const [isDev, setIsDev] = useState(false);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_VERCEL_ENV !== "production") {
      setIsDev(true);
    }
  }, []);

  const Components = (
    <HackleProvider hackleClient={HackleClient} supportSSR>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <ChakraProvider resetCSS={true} theme={chakraTheme}>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <RecoilRoot>
                <OverlayProvider>
                  <Layout>
                    <GlobalModalRoot>
                      <Component {...pageProps} />
                    </GlobalModalRoot>
                  </Layout>
                </OverlayProvider>
                <DialogRoot />
                <PreviewServiceDialogRoot />
              </RecoilRoot>
              <ReactQueryDevtools />
            </Hydrate>
          </QueryClientProvider>
        </ChakraProvider>
      </ThemeProvider>
    </HackleProvider>
  );

  return (
    <>
      {process.env.NEXT_PUBLIC_VERCEL_ENV === "production" && (
        <>
          <Script
            id="naver"
            strategy="afterInteractive"
            src="//wcs.naver.net/wcslog.js"
            onLoad={() => naverPageView()}
          />
          <Script
            id="gtm"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{ __html: googleTagManagerScript }}
          />
        </>
      )}
      <Script
        strategy="afterInteractive"
        id="beusable"
        type="text/javascript"
        dangerouslySetInnerHTML={{ __html: beusableScript }}
      />
      <Script
        strategy="afterInteractive"
        id="daum"
        type="text/javascript"
        charSet="UTF-8"
        src="//t1.daumcdn.net/kas/static/kp.js"
      />

      <Head>
        <title>{pageProps.pageOgTitle ? pageProps.pageOgTitle : "장례의 모든 것, 고이"}</title>
        <meta
          name="description"
          content={
            pageProps.pageDescription
              ? pageProps.pageDescription
              : "막막한 상황, 고이가 함께 합니다. 장례식장, 장지(납골당) 추천부터 상조 서비스, 장례 상담, 임종 접수까지. 장례의 모든것, 고이"
          }
        />
        <meta
          property="og:title"
          content={pageProps.pageOgTitle ? pageProps.pageOgTitle : "장례의 모든 것, 고이"}
        />
        <meta
          property="og:description"
          content={
            pageProps.pageDescription
              ? pageProps.pageDescription
              : "막막한 상황, 고이가 함께 합니다. 장례식장, 장지(납골당) 추천부터 상조 서비스, 장례 상담, 임종 접수까지. 장례의 모든것, 고이"
          }
        />

        {isDev && <meta name="robots" content="noindex, nofollow" />}
      </Head>
      {Components}
    </>
  );
}

export default MyApp;
