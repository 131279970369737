import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { KeyValue, colors, ColorIcon } from "goi_common";
import { ComponentProps } from "react";

interface TabProps<T extends string> {
  items: KeyValue<T>[];
  value: T;
  onChange?: (item: T) => void;
  iconNames?: Record<T, ComponentProps<typeof ColorIcon>["name"]>;
}

export function TabMenu<T extends string>({
  items,
  value,
  iconNames,
  onChange,
}: TabProps<T>) {
  return (
    <TabMenuRoot>
      <TabMenuItems>
        {items.map((item) => (
          <TabMenuItem
            key={item.key}
            length={items.length}
            isActive={item.value === value}
            onClick={() => onChange?.(item.value)}
            className="subtitle3"
            // event={`${item.key} 클릭` as OutdatedGTMEventName}
          >
            {iconNames && (
              <ColorIcon
                name={iconNames[item.value]}
                size={24}
                color={item.value === value ? colors.orange700 : colors.gray700}
              />
            )}
            <div />
            {item.key}
          </TabMenuItem>
        ))}
      </TabMenuItems>
    </TabMenuRoot>
  );
}

const TabMenuRoot = styled.div`
  padding: 0;
`;

const TabMenuItems = styled.div`
  display: flex;
`;

const TabMenuItem = styled.button<{ length: number; isActive: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 64px;
  width: calc(100% / ${({ length }) => length});
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.06);
  text-align: center;
  ${({ isActive }) =>
    isActive
      ? css`
          background: var(--white);
          color: var(--orange700);
        `
      : css`
          background: var(--gray50);
          color: var(--gray500);
        `}
  transition: 0.2s;
`;
