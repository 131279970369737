import { ChangeEvent, HTMLAttributes } from "react";
import styled from "@emotion/styled";
import React from "react";
import { HStack } from "./HStack";
import { ColorIcon } from "./Icons/ColorIcon";
import { colors } from "goi_common";
import { css } from "@emotion/react";
interface BaseProps {
  index?: number;
  checked: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
}

type Props = BaseProps &
  Omit<HTMLAttributes<HTMLInputElement>, keyof BaseProps>;

export const Checkbox = ({ index, checked, label, ...rest }: Props) => {
  const id = `checkbox-${index ?? 0}`;

  return (
    <>
      <HiddenInput type="checkbox" checked={checked} id={id} {...rest} />
      <StyledLabel htmlFor={id}>
        <HStack gap={7} alignItems="center">
          <ColorIcon
            name={checked ? "check_fill" : "check_empty"}
            size={24}
            color={checked ? colors.orange650 : colors.gray400}
            css={css`
              path {
                fill: ${checked ? "white" : colors.gray400} !important;
              }
            `}
          />
          <div className="subtitle_14_sb black">{label}</div>
        </HStack>
      </StyledLabel>
    </>
  );
};

const HiddenInput = styled.input`
  display: none;
`;

const StyledLabel = styled.label`
  width: fit-content;
`;
