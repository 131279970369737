import { cooKeys, getCookie, setCookie } from "goi_common";

export const setVisitedFeature = (
  feature:
    | "parlor-detail"
    | "parlor-region"
    | "guidebook-before"
    | "home"
    | "funeral-prepare-1"
    | "match-request"
    | "review"
    | "guidebook-conversion"
    | "guide-about-parlor-funeral"
    | "facility-detail"
    | "step-guide"
    | "home-sa-trust"
    | "home-sa-request"
    | "crematorium-detail"
    | "crematorium-region"
) => {
  let features = getCookie(cooKeys.visited_feature);
  if (!Array.isArray(features)) {
    features = [];
  }
  if (features.includes(feature)) {
    features = features.filter((f: string) => f !== feature);
  }
  features = [feature, ...features];
  setCookie(cooKeys.visited_feature, JSON.stringify(features));
};
