import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { flexCentering } from "../../styles";
import { Button, ButtonProps } from "./Button";
import React from "react";

export enum LARGEL_BUTTON_TYPE {
  DEFAULT = "default",
  DISABLED = "disabled",
  ORANGE_DISABLED = "orange_disabled",
  ORANGE = "orange",
  PRIMARY = "primary",
  GRAY900 = "gray900",
  ORANGE_OUTLINED = "orange_outlined",
  WHITE = "white",
  WARMGRAY850 = "warmgray850",
}

interface LargeButtonProps extends ButtonProps {
  buttonType: LARGEL_BUTTON_TYPE;
  height?: number;
  borderRadius?: number | string;
}

function LargeButton({
  children,
  buttonType = LARGEL_BUTTON_TYPE.DEFAULT,
  onClick,
  className,
  event,
  height = 50,
  borderRadius = 5,
  disabled,
}: LargeButtonProps) {
  return (
    <LargeButtonRoot
      className={className}
      buttonType={buttonType}
      height={height}
      borderRadius={borderRadius}
      onClick={onClick}
      event={event}
      disabled={disabled}
    >
      {children}
    </LargeButtonRoot>
  );
}

const buttonTypeToStyle = (buttonType: LARGEL_BUTTON_TYPE) => {
  switch (buttonType) {
    case LARGEL_BUTTON_TYPE.DEFAULT:
      return css`
        border: 1px solid var(--gray300);
        background-color: "transparent";
        color: var(--gray900);
      `;
    case LARGEL_BUTTON_TYPE.WHITE:
      return css`
        background-color: var(--white);
        color: var(--black);
      `;
    case LARGEL_BUTTON_TYPE.ORANGE:
      return css`
        color: white;
        background-color: var(--orange650);
      `;
    case LARGEL_BUTTON_TYPE.ORANGE_OUTLINED:
      return css`
        border: 1px solid var(--orange650);
        color: var(--orange650);
        background-color: white;
      `;
    case LARGEL_BUTTON_TYPE.PRIMARY:
      return css`
        color: white;
        background-color: var(--black);
      `;
    case LARGEL_BUTTON_TYPE.DISABLED:
      return css`
        background-color: var(--gray300);
        color: var(--white);
        cursor: default;
      `;
    case LARGEL_BUTTON_TYPE.ORANGE_DISABLED:
      return css`
        background-color: #ffd49c;
        color: var(--white);
        cursor: default;
      `;
    case LARGEL_BUTTON_TYPE.GRAY900:
      return css`
        color: white;
        background-color: var(--gray900);
      `;
    case LARGEL_BUTTON_TYPE.WARMGRAY850:
      return css`
        color: white;
        background-color: var(--warmGray850);
      `;
    default:
      break;
  }
};

const LargeButtonRoot = styled(Button)<{
  buttonType: LARGEL_BUTTON_TYPE;
  height: number;
  borderRadius: number | string;
}>`
  ${flexCentering("row")}
  width: 100%;
  height: ${({ height = 50 }) => height}px;
  border-radius: ${({ borderRadius = 5 }) =>
    typeof borderRadius === "number" ? `${borderRadius}px` : borderRadius};

  cursor: pointer;
  ${({ buttonType }) => buttonTypeToStyle(buttonType)}
`;

export { LargeButton };
