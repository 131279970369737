import { HTMLAttributes, useMemo, useState } from "react";

import { css } from "@emotion/react";
import {
  HStack,
  VStack,
  colors,
  KeyValue,
  zIndex,
  ColorIcon,
} from "goi_common";

interface BaseProps<V> {
  name?: string;
  value: V | undefined;
  values: KeyValue<V>[];
  hasError?: boolean;
  forceZIndex?: number;
  type?: DropDownType;
  width?: string;
  onChange: (value: V | undefined) => void;
}

type Props<V> = BaseProps<V> &
  Omit<HTMLAttributes<HTMLDivElement>, keyof BaseProps<V>>;

type DropDownType = "DEFAULT" | "GRAY";

function Dropdown<V>({
  name,
  value,
  values,
  onChange,
  hasError,
  forceZIndex,
  type = "DEFAULT",
  width = "fit-content",
  ...rest
}: Props<V>) {
  const [open, setOpen] = useState<boolean>(false);

  const dropdownStyleType = useMemo(() => {
    if (type === "DEFAULT") {
      return {
        backgroundColor: "white",
        borderColor: colors.gray200,
        arrowColor: colors.gray300,
        fontColor: colors.gray700,
        fontClassName: "body2",
      };
    } else if (type === "GRAY") {
      return {
        backgroundColor: colors.gray50,
        borderColor: "transparent",
        arrowColor: colors.gray600,
        fontColor: colors.gray900,
        fontClassName: "subtitle_14_sb",
      };
    }
  }, [type]);

  // const ref = useRef<HTMLDivElement>(null);
  // useClickOutside(ref, () => setOpen(false));

  return (
    <HStack
      position="relative"
      width={width}
      justifyContent="space-between"
      {...rest}
    >
      <HStack
        as="button"
        className={dropdownStyleType?.fontClassName}
        color={dropdownStyleType?.fontColor}
        zIndex={forceZIndex || zIndex.dropdown}
        gap={10}
        alignItems="center"
        width={width}
        justifyContent="space-between"
        padding="10px 12px"
        background={dropdownStyleType?.backgroundColor}
        borderRadius={3}
        border={
          hasError
            ? `1px solid ${colors.danger}`
            : `1px solid ${dropdownStyleType?.borderColor}`
        }
        css={{
          borderBottom: hasError
            ? `1px solid ${colors.danger}`
            : `1px solid ${dropdownStyleType?.borderColor}`,
        }}
        onClick={() => setOpen((o) => !o)}
      >
        {values.find((v) => v.value === value)?.key ?? name}
        <ColorIcon
          css={arrowCss({ open })}
          name="icon-arrow-right-small-mono"
          size={24}
          color={dropdownStyleType?.arrowColor || colors.gray300}
        />
      </HStack>
      {open && (
        <VStack
          position="absolute"
          top={52}
          padding="5px 0"
          width="100%"
          maxHeight={200}
          background={dropdownStyleType?.backgroundColor}
          borderRadius={3}
          border={`1px solid ${dropdownStyleType?.borderColor}`}
          overflow="auto"
          css={noScroll}
          zIndex={1100}
        >
          {values.length > 0 ? (
            values.map((v, i) => (
              <HStack
                as="button"
                justifyContent="flex-start"
                width="100%"
                padding="5px 12px"
                key={"dropdown-item-" + i + "-" + v.key}
                color={dropdownStyleType?.fontColor}
                className={dropdownStyleType?.fontClassName}
                onClick={() => {
                  onChange(v.value);
                  setOpen(false);
                }}
              >
                {v.key}
              </HStack>
            ))
          ) : (
            <HStack
              justifyContent="flex-start"
              width="100%"
              padding="5px 12px"
              className={dropdownStyleType?.fontClassName}
              color={dropdownStyleType?.fontColor}
              onClick={() => setOpen(false)}
            >
              --
            </HStack>
          )}
        </VStack>
      )}
    </HStack>
  );
}

const arrowCss = (props: { open: boolean }) =>
  css({
    transform: props.open ? "rotate(270deg)" : "rotate(90deg)",
  });

const noScroll = css({
  [`::-webkit-scrollbar`]: {
    display: "none",
  },
});

export { Dropdown };
